<template>
  <div class="" data-cy="page-not-found">
    <div ref="pageNotFound" class="text-3xl" data-cy="header-error">THIS PAGE DOES NOT EXIST</div>
    <router-link to="/" class="text-violet"
      >Go back to the home page</router-link
    >
  </div>
</template>

<script>
export default {
  name: "NotFound",
  mounted() {
    this.$refs.pageNotFound.focus(); 
  }
};
</script>

<style scoped></style>
